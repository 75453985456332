import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { BASE_URL } from "../../config/config";
import DatePickerComponent from "../Common/DatePickerComponent";
import dayjs from "dayjs";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import MyPayPalOrderButton from "../payment/PaypalOrderButton";
import Skeleton from "react-loading-skeleton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Stripe from '../../views/payment/Stripe';
import { toast } from "react-toastify";

const ReplicaRegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  animateStepTransition,
  data,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [states, setStates] = useState([]);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [file, setFile] = useState(null);
  const [selectedDob, setSelectedDob] = useState(dayjs());
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [stripePublicKey, setStripePublicKey] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [coin, setCoins] = useState("");
  const [stripeIntentStatus, setStripeIntentStatus] = useState('');
  const [isMinAge, setIsMinAge] = useState({
    status: false,
    ageLimit: data?.contactInformation?.contactField.find(
      (item) => item.code === "date_of_birth"
    ).options[0].validation.ageLimit,
  });
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const regAmount = useSelector(
    (state) => state?.dashboard?.appLayout?.configuration?.regAmount
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const sponsorImage = useSelector(
    (state) => state.replica?.sponsor?.userDetails?.image
  );

  const {
    register,
    setValue,
    trigger,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const formValues = watch();
  // console.log(formValues);
  // console.log(errors,"error");

  const steps = data?.registrationSteps;

  // ------------------------------------------- API ------------------------------------------
  const deleteBankRecipt = ApiHook.CallReplicaBankRecieptDelete(
    setSubmitButtonActive,
    setValue,
    setFileResponse,
    setFile
  );
  const checkUsernameField = ApiHook.CallReplicaFieldCheck();
  const checkEmailField = ApiHook.CallReplicaFieldCheck();
  const Upload = ApiHook.CallReplicaBankRecieptUpload(
    "register/replica",
    formValues?.username,
    data?.sponsorData?.id,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );
  const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();
  const stripeIntent = ApiHook.CallReplicaCreateStripeIntent();
  const updateStripeIntent = ApiHook.CallReplicaUpdateStripeIntent();
  const cancelStripeIntent = ApiHook.CallReplicaCancelStripeIntent();
  // ---------------------------------- Functions ------------------------------------
  const genderOptions = (data) => {
    return data.map((item) => ({
      value: item.value,
      label: t(item.title),
    }));
  };
  const countryList = (data) => {
    return data.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  };
  const stateList = (data) => {
    if (!formValues?.country) {
      return []; // Return an empty array if there's no country specified.
    }

    const selectedCountry = data.find(
      (country) => country.id === Number(formValues.country)
    );

    if (!selectedCountry) {
      return []; // Return an empty array if the selected country is not found.
    }

    return selectedCountry.States.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  };
  const changeCountry = (country) => {
    setSelectedCountry(country?.value);
    setSelectedState(null); // Clear the selected state
    setValue("state", null);
    setValue("country", country?.value); // Update the form value for country
  };
  const handleDateChange = (newDate, item) => {
    if (newDate) {
      setIsMinAge({
        status: minAgeValidation(
          newDate,
          item.options[0]?.validation?.ageLimit
        ),
        ageLimit: item.options[0]?.validation?.ageLimit,
      });
      setSelectedDob(newDate);
      const formattedDate = newDate.format("YYYY-MM-DD");
      setValue("date_of_birth", formattedDate);
      setError("date_of_birth", { message: "" });
    }
  };
  const handleDeleteBankReciept = () => {
    const data = {
      filepath: formValues?.bankReceipt,
      type: "replica",
    };
    if (formValues?.bankReceipt) {
      deleteBankRecipt.mutateAsync(data);
    }
  };
  const openCalender = () => {
    setIsCalenderOpen(true);
  };
  const closeCalender = () => {
    setIsCalenderOpen(false);
  };
  const genderChange = (gender) => {
    setSelectedGender(gender);
    setValue("gender", gender?.value);
  };
  const changeState = (state) => {
    setSelectedState(state?.value);
    setValue("state", state?.value);
  };
  const minAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const minAge = today.subtract(ageLimit, "year"); // Calculate the minimum Age
      return selectedDate.isBefore(minAge);
    } else {
      // Handle the case when selectedDate is null or undefined
      return false; // Or you can throw an error or handle it differently
    }
  };
  const handleNext = async () => {
    let isValid;
    if (
      activeStep === 3 &&
      !checkUsernameField.data?.data?.status &&
      checkUsernameField.data?.data?.field === "username" &&
      checkUsernameField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (activeStep === 1 && data?.regData) {
      setValue("pv", data?.regData);
      isValid = true;
    } else {
      isValid = await trigger();
    }
    if (!formValues?.mobile && activeStep === 2) {
      isValid = false;
      setError("mobile", { message: t("this_field_is_required") });
    }
    if ((formValues?.password)?.includes(" ") && activeStep === 3) {
      isValid = false;
      setError("password", { message: ("password_cannot_contain_white_spaces") });
    }
    if (!isPhoneNumberValid && activeStep === 2) {
      isValid = false;
      setError("mobile", {
        message: t("min_length"),
      });
    }
    if (!formValues?.date_of_birth && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("this_field_is_required"),
      });
    }
    if (!isMinAge?.status && isMinAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("ageValidation", { age: isMinAge?.ageLimit }),
      });
    }

    const productMin = formValues?.product?.min;
    const productmax = formValues?.product?.max;

    if (formValues?.investAmount < productMin || formValues?.investAmount > productmax && activeStep === 1) {
      isValid = setError("investAmount", {
        message: `Amount should be between ${productMin} and ${productmax}`,
      });
    }

    if (formValues?.investAmount % 100 !== 0 && activeStep === 1) {
      isValid = setError("investAmount", {
        message: `Amount should be multiple of 100`,
      });
    }
    if (activeStep === 1 && formValues?.investAmount ) {
      const totalAmount = Number(formValues?.investAmount) + Number(regAmount);
      setValue("totalAmt", `${totalAmount}`);
    }
    
    // below is international mobile validation : uncomment if needed
    // if (!isPhoneNumberValid && activeStep === 2) {
    //   isValid = false;
    //   setError("mobile",{message:t("invalidPhone")});
    // }

    if (isValid) {
      const nextStep = activeStep + 1;
      if (activeStep === 1){
        setValue("totalAmount", formValues?.investAmount);
      }
     
      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    setActiveTab('');
    const prevStep = activeStep - 1;
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
    // below step is to clear the uploaded image & value
    if (activeStep === 5 || activeStep === 6) {
      setValue("bankReceipt", undefined);
    }
    if (file) {
      setFile(null);
    }
  };

  const handleChange = (selectedOption) => {
    setValue("coin", selectedOption.value);
    setCoins(selectedOption.value);
    setSubmitButtonActive(false);
  };

  const coinPayment = (options) => {
    return options.map((item) => ({
      value: item,
      label: t(item),
    }));
  };

  const handleSubmit = (paymentId, paymentIntent) => {
    if (paymentId == 5) {
      formValues.stripeResponse = paymentIntent;
    }
    if (data?.sponsorData?.username) {
      setValue("placement", data?.sponsorData?.username);
      formValues.placement = data?.sponsorData?.username;
      setValue("referralId", data?.sponsorData?.id);
      formValues.referralId = data?.sponsorData?.id;
    }
    const payload = removeCustomFields(formValues, data?.contactInformation?.customField);
    replicaRegisterMutation.mutate(payload);
  };

  const handleProductClick = (productId, productName, pv, price, min, max) => {
    // const totalAmount = Number(price) + JSON.parse(data?.regFee);
    // setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
    setValue(
      "product",
      { id: productId, name: productName, price: price, min, max },
      { shouldValidate: true }
    );
    setValue("pv", pv);
   
    setValue("regAmount", data?.regFee);
  };

  const handlePaymentTabClick = (tabId, title) => {
    if (title !== "stripe" && paymentIntentId !== '') {
      const body = {
        stripeStatus: stripeIntentStatus,
        paymentIntentId: paymentIntentId
      }
      cancelStripeIntent.mutateAsync(body, {
        onSuccess: (res) => {
          if (res.status === "canceled") {
            // clear stripe
            setPaymentIntentId('');
            setClientSecret('');
            setStripeIntentStatus('');
          }
        }
      })
    }
    setActiveTab(tabId);
    if (tabId === 3) {
      setSubmitButtonActive(false);
    } else if (title === "stripe") {
      setSubmitButtonActive(true);
      setStripePublicKey(data.paymentGateways.find((item) => item.title === "stripe").publicKey);
      if (paymentIntentId === '') {
        const body = {
          productId: formValues.product.id
        };
        stripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            if (res.status) {
              setClientSecret(res.client_secret);
              setPaymentIntentId(res.id);
              setStripeIntentStatus(res.status);
            } else {
              toast.error(res.description);
            }
          }
        });
      } else {
        const body = {
          productId: formValues.product.id,
          paymentIntentId: paymentIntentId
        };
        updateStripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            setStripeIntentStatus(res.status)
          }
        })
      }
    } else {
      setSubmitButtonActive(true);
    }
    setValue("paymentType", tabId, { shouldValidate: true }); // Set the selected payment
    // if (!data?.modStatus?.productStatus) {
    //   setValue("totalAmount", data?.regData?.toFixed(2)); // regAmount is added to total amount. If there is no product
    // }
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkEmailField.mutate({ field: name, value: value });
  };

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: name, value: value });
  };

  const handleCustomField = (id, value) => {
    if (formValues.customFields?.length > 0) {
      const existingIndex = formValues.customFields.findIndex(
        (item) => item.id === id
      );
      if (existingIndex !== -1) {
        const updatedCustomField = [...formValues.customFields];
        updatedCustomField[existingIndex] = { id: id, value: value };
        setValue("customFields", updatedCustomField);
      } else {
        setValue("customFields", [
          ...formValues.customFields,
          { id: id, value: value },
        ]);
      }
    } else {
      setValue("customFields", [{ id: id, value: value }]);
    }
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      Upload.mutate(file);
    }
  };

  const handleCountry = (selectedCountry, statesData) => {
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };
  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setValue("mobile", phone);
    setError("mobile", { message: "" });
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const isPhoneValid = (phone) => {
    // international phoneUtil validation is commented
    // return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    // Add minimum length validation
    return phone.length >= 7 && !isNaN(phone);
  };
  const isPhoneNumberValid = isPhoneValid(phoneNumber);

  const removeCustomFields = (payload, customField) => {
    customField.forEach(field => {
      const key = field.code;
      delete payload[key];
    });
    return payload;
  }

  useEffect(() => {
    if (data?.modStatus?.productStatus === 0) {
      if (data?.regFee === 0) {
        setValue("pv", 0);
      } else {
        setValue("pv", data?.regFee);
      }
    };
  }, [])
  return (
    <div className="main-content-regsiter" style={{ margin: "65px" }}>
      <div className="row justify-content-center pt-0 p-4" id="wizardRow">
        <div className="col-md-12 text-center">
          <div className="wizard-form py-4 my-2">
            <ul id="progressBar" className="progressbar px-lg-5 px-0">
              {steps.map((step, index) => (
                <li
                  key={`step-${index + 1}`}
                  id={`progressList-${index + 1}`}
                  className={`d-inline-block w-15 position-relative text-center float-start progressbar-list ${(data?.regPack === null && data?.regFee === 0) ? (activeStep - 1 >= step.step ? "active" : "") : (activeStep >= step.step ? "active" : "")}`}
                >
                  {t('step')}{step.step}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="animation">
        {activeStep === 1 && (data?.regFee !== 0 || data?.regPack !== null) && (
          <div className="row row_top justify-content-center" id="cardSection">
            <div className="col-lg-12 col-md-12">
              <div className="regsiter_step_1_view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="right_product_selection_bx">
                      <div className="regsiter_step_1_view_left_sec_head">
                        {data?.registrationSteps &&
                          t(
                            `${data?.registrationSteps[activeStep - 1]?.label}`
                          )}
                      </div>
                      <div>
                        <label style={{ color: "red", margin: "5px" }}> *</label><label>Investment Amount</label>
                        <input
                          id={"investAmount"}
                          name={"investAmount"}
                          type="number"
                          className={`form-control ${errors["investAmount"] ? "error-field" : ""
                            }`}
                          // placeholder={"100,200,300, ... (Multiple of 100)"}
                          {...register("investAmount", {
                            required: {
                              value: true,
                              message: t("this_field_is_required"),
                            },

                          })}
                          onBlur={async () => await trigger("investAmount")}
                        />
                        {errors["investAmount"] && (
                          <span className="error-message-validator">
                            {errors["investAmount"].message}
                          </span>
                        )}
                      </div>
                      <div className="right_product_lsting_section">

                        <div className="row">
                          {(data?.regData || data?.regData === 0) && (
                            <div className="col-md-4">
                              <div
                                className={`right_product_box card active-card`}
                                {...register("product")}
                              >
                                <div className="right_product_box_image">
                                  <img src={"/images/product1.jpg"} alt="" />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {/* {product?.name} */}
                                  </div>
                                  <div className="right_product_box__dsc">
                                    <strong id="price">{`${userSelectedCurrency?.symbolLeft
                                      } ${CurrencyConverter(
                                        data?.regData,
                                        conversionFactor
                                      )}`}</strong>
                                    <br />
                                    {"PV - "}
                                    {data?.regData}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                  onClick={() => handleNext()}
                                >
                                  {t("buy_now")}
                                </button>
                              </div>
                            </div>
                          )}

                          {data?.regPack?.map((product, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className={`right_product_box card ${formValues.product?.id === product.id
                                  ? "active-card"
                                  : ""
                                  }`}
                                {...register("product")}
                                onClick={() =>
                                  handleProductClick(
                                    product.id,
                                    product.name,
                                    product.pairValue,
                                    product.price,
                                    product.min,
                                    product.max
                                  )
                                }
                              >
                                <div className="right_product_box_image">
                                  <img
                                    src={
                                      product?.image ?? "/images/product1.jpg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {product?.name}
                                  </div>
                                  <div className="right_product_box__dsc">
                                    <strong>
                                      {/* {currency?.symbolLeft}{" "}
                                      {CurrencyConverter(
                                        product.price,
                                        conversionFactor
                                      )} */}
                                      MIN - {product.min}
                                    </strong>
                                    <br />
                                    {"MAX - "}
                                    {product.max}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                  onClick={() => handleNext()}
                                >
                                  {t("buy_now")}
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="row row_top justify-content-center form-business">
            <div style={{ display: "flex", justifyContent: "start" }}>
              <div className="col-lg-7">
                <div className="regsiter_step_1_view_left_sec_head">
                  {t("sponsor")}
                </div>
                <div className="regsiter_step_1_view_left_user_bx_1">
                  <div className="regsiter_step_1_view_left_user_bx_image_1">
                    <img
                      src={sponsorImage ?? "/images/user-profile.png"}
                      alt=""
                    />
                  </div>
                  <div className="regsiter_step_1_view_left_user_bx_txt">
                    <strong>{data?.sponsorData?.username}</strong>
                    <p>
                      {data?.sponsorData?.UserDetail?.fullName ? (
                        <>
                          {data?.sponsorData?.UserDetail?.fullName}
                          <br />
                          {data?.sponsorData?.email}
                        </>
                      ) : (
                        <Skeleton count={2} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {data?.modStatus?.mlmPlan === "Binary" && (
                  <>
                    <div className="regsiter_step_1_view_left_sec_head">
                      {t("placement")}
                    </div>
                    <div className="regsiter_second_step_bx reg_select_pos">
                      <div className="regsiter_step_1_view_left_btn_row mt-0">
                        <label
                          className={`regsiter_step_1_view_left_btn ${formValues.position === "L" ? "active" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            name="position"
                            value={"L"}
                            {...register("position", {
                              required: true,
                            })}
                          />
                          {t("left")}
                        </label>
                        <label
                          className={`regsiter_step_1_view_left_btn ${formValues.position === "R" ? "active" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            name="position"
                            value={"R"}
                            {...register("position", {
                              required: true,
                            })}
                          />
                          {t("right")}
                        </label>
                      </div>
                      {
                        (errors["position"] && formValues.position === null) && (
                          <span className="error-message-validator">
                            {t("this_field_is_required")}
                          </span>
                        )
                      }
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="register_step_1_view_left_sec_head">
              {data?.registrationSteps && (
                data.regPack === null && data.regFee === 0
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : data.regPack === null && data.regFee !== 0
                    ? t(data?.registrationSteps[activeStep - 1]?.label)
                    : t(data?.registrationSteps[activeStep - 1]?.label)
              )}
            </div>

            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.contactInformation?.contactField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(`${item.code}`)}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" &&
                        item.code !== "state" &&
                        !item.options && (
                          <input
                            id={item.code}
                            name={item.code}
                            type="text"
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              // pattern: {
                              //   value: /^[A-Za-z0-9]+$/,
                              //   message: t("invalid_format"),
                              // },
                            })}
                            onBlur={async () => await trigger(item.code)}
                          />
                        )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${(!checkEmailField.data?.data?.status &&
                              checkEmailField.data?.data?.field === "email" &&
                              checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register("email", {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              handleEmailCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <DatePickerComponent
                          className={`date-picker ${errors[item.code] ? "error-field" : ""
                            }`}
                          date={selectedDob}
                          handleDateChange={(newDate) =>
                            handleDateChange(newDate, item)
                          }
                          isCalenderOpen={isCalenderOpen}
                          openCalender={openCalender}
                          closeCalender={closeCalender}
                        />
                      )}
                      {item.type === "number" && item.code === "mobile" && (
                        <PhoneInput
                          defaultCountry="us"
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                        />
                      )}
                      {item.type === "number" && !(item.code === "mobile") && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                        />
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <Select
                          id={item.code}
                          name={item?.code}
                          className={`dropdown-common ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          options={genderOptions(item?.options)}
                          onChange={genderChange}
                          value={selectedGender}
                          isSearchable={false}
                        />
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <Select
                          isSearchable={true}
                          id={item.code}
                          name={item?.code}
                          className={`replica-reg-country ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          value={countryList(item?.options).find(
                            (item) => item.value === selectedCountry
                          )}
                          options={countryList(item?.options)}
                          onChange={changeCountry}
                        />
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <Select
                          isSearchable={false}
                          id={item.code}
                          name={item?.code}
                          className={` ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          options={stateList(item?.options)}
                          onBlur={async () => await trigger(item.code)}
                          value={stateList(item?.options).find(
                            (state) => state?.value === selectedState
                          )}
                          onChange={changeState}
                        />
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {data?.contactInformation?.customField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {item.value}{" "}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" && !item.options && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="text"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            // pattern: {
                            //   value: /^[A-Za-z0-9]+$/,
                            //   message: t("invalid_format"),
                            // },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${(!checkEmailField.data?.data?.status &&
                              checkEmailField.data?.data?.field === "email" &&
                              checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleCustomField(item.id, inputValue);
                            }}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="date"
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              validate: (value) => validateAge(value, t),
                            })}
                            onChange={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleCustomField(item.id, inputValue);
                              await trigger(item.code);
                            }}
                          />
                          {errors.date_of_birth && (
                            <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.type === "textarea" && (
                        <textarea
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "number" && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.code] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.title}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${errors[item.name] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCountry(e.target.value, item.options);
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          <option>{t("select_the_country")}</option>
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <select
                          id={item.code}
                          name={item.name}
                          className={`form-control ${errors[item.name] ? "error-field" : ""
                            }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {states?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps && (
                data.regPack === null && data.regFee === 0
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : data.regPack === null && data.regFee !== 0
                    ? t(data?.registrationSteps[activeStep - 1]?.label)
                    : t(data?.registrationSteps[activeStep - 1]?.label)
              )}
            </div>
            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.loginInformation.map((item, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(item.code)} <span className="text-danger">٭</span>
                      </label>
                      {item.type === "text" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={item.type}
                            className={`form-control ${(!checkUsernameField.data?.data?.status &&
                              checkUsernameField.data?.data?.field ===
                              "username" &&
                              checkUsernameField.data?.data?.code === 1117) ||
                              errors[item.code]
                              ? "error-field"
                              : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginUsernameValidator(item, t)
                            )}
                            onChangeCapture={async (e) => {
                              await handleUsernameCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkUsernameField.data?.data?.status &&
                            checkUsernameField.data?.data?.field ===
                            "username" &&
                            checkUsernameField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("username_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "password" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={showPassword ? "text" : "password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${errors[item.code] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginPasswordValidator(item, t)
                            )}
                            onBlur={async () => await trigger(item.code)}
                          />
                          <InputAdornment
                            position="end"
                            className="replica-eye"
                          >
                            <IconButton
                              onClick={handleShowPassword}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                          <PasswordChecklist
                            rules={passwordRules(item.validation)}
                            minLength={item.validation.minLength}
                            value={watch("password", "")}
                            className="mb-3"
                          />
                          <label htmlFor={"confirmPassword"}>
                            {t("confirmPassword")}{" "}
                            <span className="text-danger">٭</span>
                          </label>
                          <input
                            id={"confirmPassword"}
                            name={"confirmPassword"}
                            type={"password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${errors["confirmPassword"] ? "error-field" : ""
                              }`}
                            placeholder={item.placeholder}
                            {...register("confirmPassword", {
                              required: {
                                message: t("this_field_is_required"),
                              },
                              validate: (value) =>
                                value === getValues("password") ||
                                t("passwordNotMatch"),
                            })}
                            onBlur={async () =>
                              await trigger("confirmPassword")
                            }
                          />
                        </>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {t(errors[item.code].message)}
                        </span>
                      )}
                      {errors["confirmPassword"] &&
                        item.code !== "username" && (
                          <span className="error-message-validator">
                            {errors["confirmPassword"].message}
                          </span>
                        )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <div className="regsiter_form_box">
                    <label className="d-flex" htmlFor="box">
                      <input
                        name={"termsAndCondition"}
                        type="checkbox"
                        id="box"
                        style={{ marginRight: "5px" }}
                        {...register("termsAndCondition", {
                          required: true,
                        })}
                      />
                      <a
                        data-bs-toggle="modal"
                        className="pop_terms_btn"
                        onClick={handleToggleRegisterModal}
                      >
                        {t("iAcceptTermsAndConditions")}
                        <span className="text-danger"> </span>
                      </a>
                    </label>
                    {errors["termsAndCondition"] &&
                      formValues["termsAndCondition"] === false && (
                        <span className="error-message-validator">
                          *{t("required")}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
         {activeStep === 4 && (
          <div className="row row_top form-business">
            {/* <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps &&
                ((data.regPack === null || data.regPack.length === 0) &&
                (data.regData === 0 || data.regData === null)
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : (data.regPack === null || data.regPack.length === 0) &&
                    (data.regData !== 0 || data.regData !== null)
                  ? t(data?.registrationSteps[activeStep - 1]?.label)
                  : t(data?.registrationSteps[activeStep - 1]?.label))}
            </div> */}
            <div className="regsiter_second_step_bx">
              <div className="row">
                <>
                <div class="agreement-container">
                    <div class="agreement-header">
                      <h1 class="agreement-title">BOI Investment Agreement</h1>
                    </div>

                    <div class="agreement-items">
                      <div class="agreement-item">
                      <div class="item-title">
                        I, {formValues.first_name ?? "----------------"}{" "}{formValues.last_name}, hereby declare that:
                        </div>
                        <div class="item-content">
                          1. I am making a personal investment in BOI, independent of any efforts by the CEO or Support Team. In the event that the CEO, who oversees trading activities for the BOI community and maintains investment records, is unable to continue in this capacity, I am prepared to assume full responsibility.
                        </div>
                      </div>

                      <div class="agreement-item">
                        {/* <div class="item-title">
                          2. Non-Disparagement Agreement
                        </div> */}
                        <div class="item-content">
                          2. I agree to refrain from tarnishing the reputation of BOI Company, its Support Team, and CEO on all social media platforms. Failure to comply with this declaration will result in legal action being taken against me, and I will forfeit my investment.
                        </div>
                      </div>

                      <div class="agreement-item">
                        {/* <div class="item-title">
                          3. Payment Resolution Process
                        </div> */}
                        <div class="item-content">
                          3. I acknowledge that a 48-hour waiting period applies for payments. After this period, I may submit a formal complaint to the Support Team. If the Support Team fails to respond within 24 hours, I may escalate the matter to a BOI Support Team member.
                        </div>
                      </div>

                      <div class="agreement-item">
                        {/* <div class="item-title">
                          4. Stokvel Investment Terms
                        </div> */}
                        <div class="item-content">
                          4. If I choose to invest in one of the two authorized stokvels acknowledged by the BOI CEO and Support Team:
                        </div>

                        <div class="sub-item">
                          {/* <div class="sub-item-title">
                            4.1. Direct Investment Responsibility */}
                          {/* </div> */}
                          <div class="sub-item-content">
                            4.1. I understand that I submit investment funds directly to the Stokvel Administrator and that the CEO cannot be held accountable for non-payment, except in cases where prior communication indicated ineligibility.
                          </div>
                        </div>

                        <div class="sub-item">
                          {/* <div class="sub-item-title">
                            4.2. Stokvel Administration Authorization
                          </div> */}
                          <div class="sub-item-content">
                            4.2.  I acknowledge that authorization to administer the stokvel will be granted by the CEO after consultation with the Support Team, who will provide a report following their verification.
                          </div>
                        </div>
                      </div>

                      <div class="agreement-item">
                        <div class="item-content">
                          5.  I acknowledge that BOI reserves the right to determine who can invest.
                        </div>
                      </div>
                      {/* <div class="agreement-item"> */}
                        {/* <div class="item-title">5. Right of Admission</div> */}
                        <div class="item-title">
                        By electronically signing below, I confirm that I have read, understood, and agreed to the terms and conditions outlined in this declaration.
                        </div>
                      {/* </div> */}
                    </div>
                    <div className="col-md-12" style={{marginTop:"10px"}}>
                      <div className="regsiter_form_box_tandc">
                        <label className="d-flex" htmlFor="box">
                          <input
                            name={"declaration"}
                            type="checkbox"
                            id="box"
                            style={{ marginRight: "5px" }}
                            {...register("declaration", {
                              required: true,
                            })}
                          />
                        </label>
                        <a
                          data-bs-toggle="modal"
                          className="pop_terms_btn"
                          // onClick={handleToggleRegisterModal}
                        >
                          {t("Accept Investment Agreement")}
                          <span
                            className="text-danger"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                          </span>
                        </a>
                        {errors["declaration"] &&
                          formValues["declaration"] === false && (
                            <span className="error-message-validator">
                              {" "}
                              *{t("required")}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 5 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_second_step_bx">
              <div className="regsiter_overview_box">
                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t("product_and_sponsor")}</strong>
                </div>
                <div className="row">
                  {data?.modStatus?.productStatus !== 0 && (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="product">{t("product")}</label>
                      <strong id="product">{formValues?.product?.name}</strong>
                    </div>
                  )}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="sponsor">{t("sponsor")}</label>
                    <strong id="sponsor">{data?.sponsorData?.username}</strong>
                  </div>
                  {data?.regFee !== 0 &&
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="pv">{t("pv")}</label>
                      <strong id="pv">{formValues?.pv ?? "0"}</strong>
                    </div>
                  }
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="price">{t("investAmount")}</label>
                    <strong id="price">{`${userSelectedCurrency?.symbolLeft
                      } ${CurrencyConverter(
                        formValues?.investAmount ?? data?.regData,
                        conversionFactor
                      )}`}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="total">{t("totalAmount")}</label>
                    <strong id="total">{`${userSelectedCurrency?.symbolLeft
                      } ${CurrencyConverter(
                        formValues?.totalAmount ?? data?.regData,
                        conversionFactor
                      )}`}</strong>
                  </div>
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                </div>

                <div className="row">
                  {Object.entries(formValues)?.map(
                    ([key, value]) =>
                      ![
                        "product",
                        "pv",
                        "regAmount",
                        "declaration",
                        "termsAndCondition",
                        "totalAmt",
                        "paymentType",
                        "customFields",
                        "bankReceipt",
                        "referralId",
                        "totalAmount",
                        "username",
                        "password",
                        "country",
                        "state",
                        "confirmPassword",
                        "position",
                      ].includes(key) && (
                        <div
                          className="col-md-4 mb-3 regsiter_overview_col"
                          key={key}
                        >
                          <label htmlFor={`input-${key}`}>{t(key)}</label>
                          <strong id={`input-${key}`}>{value}</strong>
                        </div>
                      )
                  )}
                  {formValues?.customField?.map(([key, value]) => (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor={`input-${key}`}>{t(key)}</label>
                      <strong id={`input-${key}`}>{value}</strong>
                    </div>
                  ))}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[2]?.label}`)}</strong>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="username">{t("username")}</label>
                    <strong id="username">{formValues.username}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="password">{t("password")}</label>
                    <strong id="password">********</strong>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 6 && (
          <div className="row row_top justify-content-center form-business">
            <div className="col-lg-12 col-md-12" id="payment">
              <div className="payment_section_tab">
                <div className="regsiter_step_1_view_left_sec_head">
                  {data?.registrationSteps && (
                    data.regPack === null && data.regFee === 0
                      ? t(data?.registrationSteps[activeStep - 2]?.label)
                      : data.regPack === null && data.regFee !== 0
                        ? t(data?.registrationSteps[activeStep - 1]?.label)
                        : t(data?.registrationSteps[activeStep - 1]?.label)
                  )}
                  <br />
                  <strong>
                    {t("totalAmount")}:{" "}
                    {`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(
                      formValues?.totalAmount ?? data?.regFee,
                      conversionFactor
                    )}`}
                  </strong>
                </div>

                <div className="row">
                  <div className="col-md-3 pe-0">
                    <div
                      className="nav nav-tabs payment_tab_reg"
                      id="nav-tab"
                      role="tablist"
                    >
                      {data?.paymentGateways?.map((tab) => (
                        <Fragment key={tab.id}>
                          <button
                            key={tab.id}
                            className={`nav-link ${activeTab === tab.id ? "active" : ""
                              }`}
                            id={`nav-${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`nav-${tab.id}`}
                            aria-selected={formValues.paymentType === tab.id}
                            {...register("paymentType", { required: true })}
                            onClick={() =>
                              handlePaymentTabClick(tab.id, tab.title)
                            }
                          >
                            <i className={tab?.icon}></i> {t(tab.title)}
                          </button>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-9 ps-0">
                    <div
                      className="tab-content p-3 border mb-5 payment__tab_cnt"
                      id="nav-tabContent"
                    >
                      {(formValues.paymentType === undefined ||
                        formValues.paymentType === "") && (
                          <p>
                            <strong>{t("preferredPaymentPlaceholder")}</strong>
                          </p>
                        )}

                      {data?.paymentGateways?.map((tab) => (
                        <div
                          key={tab.id}
                          className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                            }`}
                          id={`nav-${tab.id}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${tab.id}-tab`}
                        >
                          {tab.title === "free-joining" && (
                            <p>
                              <strong>{t("freeJoinPlaceHolder")}</strong>
                            </p>
                          )}
                          {tab.title === "bank-transfer" && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <label
                                    htmlFor="fileUpload"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {t("file_upload")}
                                  </label>
                                  <input
                                    id="bankReciept"
                                    type="file"
                                    placeholder=""
                                    className="form-control"
                                    style={{ height: "33px" }}
                                    name="fileUpload"
                                    onChange={handleFileChange}
                                  />
                                  {fileResponse?.success &&
                                    formValues?.bankReceipt !== undefined && (
                                      <div style={{ color: "green" }}>
                                        {t(fileResponse?.success)}
                                      </div>
                                    )}
                                  {fileResponse?.error && (
                                    <div style={{ color: "red" }}>
                                      {t(fileResponse?.error)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button
                                  href="#"
                                  className="btn btn-sm btn-primary mt-3"
                                  onClick={handleUpload}
                                  disabled={
                                    Upload.status === "loading" ||
                                    formValues?.bankReceipt ||
                                    document.getElementById("bankReciept")
                                      ?.value === "" ||
                                    file === null
                                  }
                                >
                                  {Upload.status === "loading"
                                    ? "Uploading..."
                                    : t("upload")}
                                </button>
                              </div>
                              {Upload?.data?.status === true &&
                                formValues?.bankReceipt !== undefined && (
                                  <>
                                    <img
                                      src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                      alt="receipt"
                                      style={{
                                        width: "160px",
                                        height: "160px",
                                        padding: "5px",
                                      }}
                                    />
                                    <button
                                      className="recipt_checkout_address_btn"
                                      onClick={handleDeleteBankReciept}
                                    >
                                      <i className="fa fa-xmark"></i>
                                    </button>
                                    <div style={{ color: "green" }}>
                                      {Upload?.data?.message}
                                    </div>
                                  </>
                                )}
                              {Upload?.data?.status === false && (
                                <span className="error-message-validator">
                                  {Upload?.data?.message}
                                </span>
                              )}
                            </div>
                          )}
                          {tab.title === "stripe" && clientSecret !== '' && (
                            <p>
                              <Stripe clientSecret={clientSecret} handleSubmit={handleSubmit} publicKey={stripePublicKey} />
                            </p>
                          )}
                          {tab.title === "paypal" && (
                            <MyPayPalOrderButton
                              currency={userSelectedCurrency?.code}
                              price={formValues.totalAmount}
                              handleSubmit={handleSubmit}
                              paymentId={tab.id}
                            />
                          )}
                          {tab.title === "coin-payment" && (
                            <>
                              <label htmlFor="coin">
                                {t("Select Coin")}
                              </label>
                              <Select
                                isSearchable={false}
                                id={"coin"}
                                name={"coin"}
                                className={`${
                                  errors.coin ? "error-field" : ""
                                }`}
                                // {...register("coin", {
                                //   required: {
                                //     value: true,
                                //     message: t("this_field_is_required"),
                                //   },
                                // })}
                                options={coinPayment(tab.coins)}
                                onBlur={async () => await trigger("coin")}
                                value={coinPayment(tab?.coins).find(
                                  (option) => option.value === coin
                                )}
                                onChange={handleChange}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.paymentType && (
                  <span className="error-message-validator">*Required</span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-dark text-white float-start back rounded-3 bg-color-back"
                onClick={handleBack}
              >
                {t("back")}
              </button>
              {activeTab !== 6 &&
                <button
                  type="submit"
                  className="btn text-white float-end submit-button rounded-3 bg-color-info"
                  disabled={submitButtonActive}
                  onClick={handleSubmit}
                >
                  {t("finish")}
                </button>
              }
            </div>
          </div>
        )}
      </div>
      {replicaRegisterMutation.status === "loading" && <div className="reg-submit-loader-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <path fill="none" stroke="#7b33c2" stroke-width="8" stroke-dasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}>
            <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
          </path></svg>
      </div>}
    </div>
  );
};

export default ReplicaRegisterForm;